import Header from "../components/Header";
import "../styles/CV.css";

const Contact = () => {
  return (
    <>
      <Header isHome={false} />
      <div className="CVContainer">
        <p>songminji1918@gmail.com</p>
      </div>
    </>
  );
};

export default Contact;
